import React from 'react';
import Layout from 'components/layout';
import Title from 'components/title';

const Roadmap = () => (
  <Layout>
    <section className="jumbotron">
      <Title as="h2" weight="light">
        This is a roadmap
      </Title>
    </section>
  </Layout>
);

export default Roadmap;
